@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

a {
  text-decoration: none;
  font-family: 'Poppins';
}

li {
  list-style: none;
  font-family: 'Poppins';
}

:root {
  --poppins: 'Poppins', sans-serif;
  --lato: 'Lato', sans-serif;

  --light: #F9F9F9;
  --blue: #3C91E6;
  --light-blue: #CFE8FF;
  --grey: #eee;
  --dark-grey: #AAAAAA;
  --dark: #342E37;
  --red: #DB504A;
  --yellow: #FFCE26;
  --light-yellow: #FFF2C6;
  --orange: #FD7238;
  --light-orange: #FFE0D3;
}

html {
  overflow-x: hidden;
}

body.dark {
  --light: #0C0C1E;
  --grey: #060714;
  --dark: #FBFBFB;
}

body {
  background: var(--grey);
  overflow-x: hidden;
  font-family: 'Poppins';
}





/* SIDEBAR */
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  height: 100%;
  background: var(--light);
  z-index: 2000;
  font-family: var(--lato);
  transition: .3s ease;
  overflow-x: hidden;
  scrollbar-width: none;
}
#sidebar::--webkit-scrollbar {
  display: none;
}
#sidebar.hide {
  width: 60px;
}
#sidebar .brand {
  font-size: 24px;
  font-weight: 700;
  height: 56px;
  display: flex;
  align-items: center;
  color: var(--blue);
  position: sticky;
  top: 0;
  left: 0;
  background: var(--light);
  z-index: 500;
  padding-bottom: 20px;
  box-sizing: content-box;
}
#sidebar .brand .bx {
  min-width: 60px;
  display: flex;
  justify-content: center;
}
#sidebar .side-menu {
  width: 100%;
  margin-top: 48px;
}
#sidebar .side-menu li {
  height: 48px;
  background: transparent;
  margin-left: 6px;
  border-radius: 48px 0 0 48px;
  padding: 4px;
}
#sidebar .side-menu li.active {
  background: var(--grey);
  position: relative;
}
#sidebar .side-menu li.active::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -40px;
  right: 0;
  box-shadow: 20px 20px 0 var(--grey);
  z-index: -1;
}
#sidebar .side-menu li.active::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: -40px;
  right: 0;
  box-shadow: 20px -20px 0 var(--grey);
  z-index: -1;
}
#sidebar .side-menu li a {
  width: 100%;
  height: 100%;
  background: var(--light);
  display: flex;
  align-items: center;
  border-radius: 48px;
  font-size: 16px;
  color: var(--dark);
  white-space: nowrap;
  overflow-x: hidden;
  text-decoration: none;
}
#sidebar .side-menu.top li.active a {
  color:#FF3E56;
}
#sidebar.hide .side-menu li a {
  width: calc(48px - (4px * 2));
  transition: width .3s ease;
}
#sidebar .side-menu li a.logout {
  color: var(--red);
}
#sidebar .side-menu.top li a:hover {
  color: #FF3E56;
}
#sidebar .side-menu li a .bx {
  min-width: calc(60px  - ((4px + 6px) * 2));
  display: flex;
  justify-content: center;
}
/* SIDEBAR */





/* CONTENT */
#content {
  position: relative;
  width: calc(100% - 280px);
  left: 280px;
  transition: .3s ease;
}
#sidebar.hide ~ #content {
  width: calc(100% - 60px);
  left: 60px;
}




/* NAVBAR */
#content nav {
  height: 56px;
  background: var(--light);
  padding: 0 24px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  font-family: var(--lato);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}
#content nav::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -40px;
  left: 0;
  border-radius: 50%;
  box-shadow: -20px -20px 0 var(--light);
}
#content nav a {
  color: var(--dark);
}
#content nav .bx.bx-menu {
  cursor: pointer;
  color: var(--dark);
}
#content nav .nav-link {
  font-size: 16px;
  transition: .3s ease;
}
#content nav .nav-link:hover {
  color: var(--blue);
}
#content nav form {
  max-width: 400px;
  width: 100%;
  margin-right: auto;
}
#content nav form .form-input {
  display: flex;
  align-items: center;
  height: 36px;
}
#content nav form .form-input input {
  flex-grow: 1;
  padding: 0 16px;
  height: 100%;
  border: none;
  background: var(--grey);
  border-radius: 36px 0 0 36px;
  outline: none;
  width: 100%;
  color: var(--dark);
}
#content nav form .form-input button {
  width: 36px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blue);
  color: var(--light);
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 0 36px 36px 0;
  cursor: pointer;
}
#content nav .notification {
  font-size: 20px;
  position: relative;
}
#content nav .notification .num {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--light);
  background: var(--red);
  color: var(--light);
  font-weight: 700;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#content nav .profile img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}
#content nav .switch-mode {
  display: block;
  min-width: 50px;
  height: 25px;
  border-radius: 25px;
  background: var(--grey);
  cursor: pointer;
  position: relative;
}
#content nav .switch-mode::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: calc(25px - 4px);
  background: var(--blue);
  border-radius: 50%;
  transition: all .3s ease;
}
#content nav #switch-mode:checked + .switch-mode::before {
  left: calc(100% - (25px - 4px) - 2px);
}
/* NAVBAR */





/* MAIN */
#content main {
  width: 100%;
  padding: 36px 24px;
  font-family: var(--poppins);
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
#content main .head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
#content main .head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--dark);
}
#content main .head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}
#content main .head-title .left .breadcrumb li {
  color: var(--dark);
}
#content main .head-title .left .breadcrumb li a {
  color: var(--dark-grey);
  pointer-events: none;
}
#content main .head-title .left .breadcrumb li a.active {
  color: var(--blue);
  pointer-events: unset;
}
#content main .head-title .btn-download {
  height: 36px;
  padding: 0 16px;
  border-radius: 36px;
  background: var(--blue);
  color: var(--light);
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  font-weight: 500;
}




#content main .box-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 24px;
  margin-top: 36px;
}
.dashstat{

}
#content main .box-info li {
  padding: 0px;
  background: var(--light);
  border-radius: 20px;
  box-shadow: 0px 5px 20px 0px rgba(92,97,242,.2);
  display: flex;
  align-items: center;
  grid-gap: 24px;
}
#content main .box-info li .bx {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#content main .box-info li:nth-child(1) .bx {
  background: var(--light-blue);
  color: var(--blue);
}
#content main .box-info li:nth-child(2) .bx {
  background: var(--light-yellow);
  color: var(--yellow);
}
#content main .box-info li:nth-child(3) .bx {
  background: var(--light-orange);
  color: var(--orange);
}
#content main .box-info li .text h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--dark);
}
#content main .box-info li .text p {
  color: var(--dark);  
}





#content main .table-data {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}
#content main .table-data > div {
  border-radius: 20px;
  background: var(--light);
  padding: 24px;
  overflow-x: auto;
}
#content main .table-data .head {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 24px;
}
#content main .table-data .head h3 {
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
}
#content main .table-data .head .bx {
  cursor: pointer;
}

#content main .table-data .order {
  flex-grow: 1;
  flex-basis: 500px;
}
#content main .table-data .order table {
  width: 100%;
  border-collapse: collapse;
}
#content main .table-data .order table th {
  padding-bottom: 12px;
  font-size: 13px;
  text-align: left;
  border-bottom: 1px solid var(--grey);
}
#content main .table-data .order table td {
  padding: 16px 0;
}
#content main .table-data .order table tr td:first-child {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  padding-left: 6px;
}
#content main .table-data .order table td img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
#content main .table-data .order table tbody tr:hover {
  background: var(--grey);
}
#content main .table-data .order table tr td .status {
  font-size: 10px;
  padding: 6px 16px;
  color: var(--light);
  border-radius: 20px;
  font-weight: 700;
}
#content main .table-data .order table tr td .status.completed {
  background: var(--blue);
}
#content main .table-data .order table tr td .status.process {
  background: var(--yellow);
}
#content main .table-data .order table tr td .status.pending {
  background: var(--orange);
}


#content main .table-data .todo {
  flex-grow: 1;
  flex-basis: 300px;
}
#content main .table-data .todo .todo-list {
  width: 100%;
}
#content main .table-data .todo .todo-list li {
  width: 100%;
  margin-bottom: 16px;
  background: var(--grey);
  border-radius: 10px;
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#content main .table-data .todo .todo-list li .bx {
  cursor: pointer;
}
#content main .table-data .todo .todo-list li.completed {
  border-left: 10px solid var(--blue);
}
#content main .table-data .todo .todo-list li.not-completed {
  border-left: 10px solid var(--orange);
}
#content main .table-data .todo .todo-list li:last-child {
  margin-bottom: 0;
}
/* MAIN */
/* CONTENT */









@media screen and (max-width: 768px) {
  #sidebar {
    width: 200px;
  }

  #content {
    width: calc(100% - 60px);
    left: 200px;
  }

  #content nav .nav-link {
    display: none;
  }
}






@media screen and (max-width: 576px) {
  #content nav form .form-input input {
    display: none;
  }

  #content nav form .form-input button {
    width: auto;
    height: auto;
    background: transparent;
    border-radius: none;
    color: var(--dark);
  }

  #content nav form.show .form-input input {
    display: block;
    width: 100%;
  }
  #content nav form.show .form-input button {
    width: 36px;
    height: 100%;
    border-radius: 0 36px 36px 0;
    color: var(--light);
    background: var(--red);
  }

  #content nav form.show ~ .notification,
  #content nav form.show ~ .profile {
    display: none;
  }

  #content main .box-info {
    grid-template-columns: 1fr;
  }

  #content main .table-data .head {
    min-width: 420px;
  }
  #content main .table-data .order table {
    min-width: 420px;
  }
  #content main .table-data .todo .todo-list {
    min-width: 420px;
  }
}
.data-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Poppins';
}

.data-table th, .data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color:#2dad57;
  color: white;
}

.data-table td {
  vertical-align: top
}

.inbox {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.messages-container {
  display: flex;
  flex-direction: column;
}

.message {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }
}

.message-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sender {
  font-weight: bold;
}

.message-content {
  color: #555;
}

/* ChatModal styles (modify according to your design) */
.modal-body {
  max-height: 300px;
  overflow-y: auto;
}
.chat-box {
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 80%;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  word-wrap: break-word;
}

.you {
  align-self: flex-end;
  background-color: #4caf50; /* Light green for your messages */
  color: #fff;
}

.client {
  align-self: flex-start;
  background-color: #f2f2f2; /* Light gray for client's messages */
}

.input-box {
  display: flex;
}

.input-box input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.input-box button {
  padding: 8px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.modal-dialog{
  left: 8%;
}
.modal-body{max-height: 850px;}
/* inbox */

/* inbox */

.visitor-chart{
  width: 450px;
}
.chart-row{
  display: inline-flex;
  width: 100%;
}
.chart1, .chart2{
  width: 50%;
}
.reivchart{
  width: 70%;
  margin: auto;
  margin-left: 2%;
}
.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}
.toast {
  right: 5%;
    position: absolute;
    z-index: 199999;
}
.box-bg{
  box-shadow:0px 5px 20px 0px rgba(92,97,242,.2);
  padding: 12px;
}
/* Add styling to the scrollbar */
.scrollable-table-container {
  scrollbar-width: thin; /* Adjust the scrollbar width */
  scrollbar-color: darkgray lightgray;
}

.scrollable-table-container::-webkit-scrollbar {
  width: 5px; /* Adjust the scrollbar width */
}

.scrollable-table-container::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.scrollable-table-container::-webkit-scrollbar-track {
  background-color: lightgray;
}
/* chat messagss */
.text-small {
  font-size: 0.9rem;
}

.messages-box,
.chat-box {
  height: 510px;
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}
/* chat messsages */
.clinet{
  text-align: right;
}